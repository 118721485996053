import Article from '../../Components/Article/Article'

const News = () => {
    return (
        <>
            <Article />
        </>
    )
}

export default News
