import { useState } from 'react'
import { Link } from 'react-router-dom'
import hubapLogo from '../../Assets/hubapLogo.svg'

const Navbar = () => {
    const [sideMenuVisibility, setSideMenuVisibility] = useState(false)

    const toggleSideMenuVisibility = () => {
        console.log('sideMenuVisibility : ', sideMenuVisibility)
        setSideMenuVisibility(!sideMenuVisibility)
    }

    return (
        <div className="w-full  fixed">
            <div className="max-w-screen-2xl mx-auto">
                <nav className="relative px-4 py-4 flex justify-between items-center bg-gray-1000">
                    <img src={hubapLogo} alt="Logo Hubap" className="h-10" />

                    <div className="lg:hidden">
                        <button
                            className="navbar-burger flex items-center text-blue-600 p-2"
                            onClick={() => {
                                toggleSideMenuVisibility()
                            }}
                        >
                            <svg
                                className="block h-4 w-4 fill-current"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <title>Mobile menu</title>
                                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
                            </svg>
                        </button>
                    </div>
                    <ul className="hidden lg:flex lg:items-center  lg:space-x-6">
                        <li>
                            <Link
                                className="text-sm text-gray-400 hover:text-gray-500"
                                to={'/'}
                            >
                                Home
                            </Link>
                        </li>

                        <li className="text-gray-300">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                stroke="currentColor"
                                className="w-4 h-4 current-fill"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M12 5v0m0 7v0m0 7v0m0-13a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                                />
                            </svg>
                        </li>
                        <li>
                            <Link
                                className="text-sm text-blue-600 font-bold"
                                to={'/news'}
                            >
                                News
                            </Link>
                        </li>
                    </ul>
                </nav>
                {sideMenuVisibility && (
                    <div className="navbar-menu relative z-50">
                        <div className="navbar-backdrop fixed inset-0 bg-gray-800 opacity-25"></div>
                        <nav className="fixed top-0 left-0 bottom-0 right-0 flex flex-col w-100 py-4 px-4 bg-white border-r overflow-y-auto">
                            <div className="flex items-center mb-8">
                                <Link
                                    className="mr-auto text-3xl font-bold leading-none"
                                    to={'/'}
                                >
                                    <img
                                        src={hubapLogo}
                                        alt="Logo Hubap"
                                        className="h-10"
                                    />
                                </Link>
                                <button
                                    className="navbar-close"
                                    onClick={() => {
                                        toggleSideMenuVisibility()
                                    }}
                                >
                                    <svg
                                        className="h-6 w-6 text-gray-400 cursor-pointer hover:text-gray-500"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="M6 18L18 6M6 6l12 12"
                                        ></path>
                                    </svg>
                                </button>
                            </div>
                            <div>
                                <ul>
                                    <li className="mb-1">
                                        <Link
                                            className="block p-4 text-sm font-semibold text-gray-400 hover:bg-blue-50 hover:text-blue-600 rounded"
                                            to={'/'}
                                        >
                                            Home
                                        </Link>
                                    </li>
                                    <li className="mb-1">
                                        <Link
                                            className="block p-4 text-sm font-semibold text-gray-400 hover:bg-blue-50 hover:text-blue-600 rounded"
                                            to={'/news'}
                                        >
                                            News
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="mt-auto">
                                {/*                     <div className="pt-6">
                        <a className="block px-4 py-3 mb-3 leading-loose text-xs text-center font-semibold leading-none bg-gray-50 hover:bg-gray-100 rounded-xl" href="#">Sign in</a>
                        <a className="block px-4 py-3 mb-2 leading-loose text-xs text-center text-white font-semibold bg-blue-600 hover:bg-blue-700  rounded-xl" href="#">Sign Up</a>
                    </div> */}
                                <p className="my-4 text-xs text-center text-gray-400">
                                    <span>Copyright © 2022</span>
                                </p>
                            </div>
                        </nav>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Navbar
