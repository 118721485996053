import { Link } from 'react-router-dom'

const SectionCard3 = (props: any) => {
    const logoPath = require('../../Assets/' + props.logo)

    return (
        <Link to={''} className="w-2/3 md:w-96 h-48 m-10">
            <div
                className="
                p-1
                rounded-lg 
                bg-gradient-to-tr from-red-700 to-blue-400 
                "
            >
                <div className="bg-gray-800 p-0.5 rounded-md">
                    <div className="bg-gray-1000 rounded p-5 flex flex-col md:flex-row items-center">
                        <div className="flex-grow">
                            <img
                                src={logoPath}
                                alt="Logo Hubap"
                                className=" h-24 mx-auto"
                            />
                        </div>
                        <div className="flex-grow">
                            <h5 className="flex-grow text-xl font-bold tracking-tight text-transparent bg-clip-text bg-gradient-to-tr from-red-600 to-blue-400">
                                {props.title}
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    )
}

export default SectionCard3
