import { Outlet } from 'react-router-dom'
import Navbar from '../../Components/Navbar/Navbar'

const Home = () => {
    return (
        <>
            <Navbar />
            <div>
                <Outlet />
            </div>
        </>
    )
}

export default Home
