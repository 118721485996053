import hubapLogo from '../../Assets/hubapLogo_hero.svg'

const SectionHero = () => {
    return (
        <>
            <div className="h-screen bg-gray-1000 flex md:flex-row flex-col items-center justify-center">
                <h2 className="text-3xl md:text-6xl text-center md:text-right text-gray-100 font-thin m-10">
                    bienvenue
                    <br />
                    dans le{' '}
                    <span className="font-semibold text-transparent bg-clip-text bg-gradient-to-tr from-red-700 to-blue-400">
                        HUB
                    </span>
                    <br />
                    d'
                    <span className="font-semibold text-transparent bg-clip-text bg-gradient-to-tr from-red-700 to-blue-400">
                        A
                    </span>
                    rnaud{' '}
                    <span className="font-semibold text-transparent bg-clip-text bg-gradient-to-tr from-red-700 to-blue-400">
                        P
                    </span>
                    apa
                </h2>
                <img
                    src={hubapLogo}
                    alt="Logo Hubap"
                    className="h-40 md:h-80 m-10"
                />
            </div>
        </>
    )
}

export default SectionHero
