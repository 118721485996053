import React from 'react'
import './App.css'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import Home from './Pages/HomePage/Home'
import About from './Pages/AboutPage/About'
import News from './Pages/NewsPage/News'

const router = createBrowserRouter([
    {
        path: '/',
        element: <Home />,
        children: [
            {
                path: '/',
                element: <About />,
            },
            {
                path: '/news',
                element: <News />,
            },
        ],
    },
])

function App() {
    return (
        <div className="bg-gray-1000">
            <RouterProvider router={router} />
        </div>
    )
}

export default App
