const Code = (props: any) => {
    return (
        <div className="bg-gradient-to-tr from-red-700 to-blue-400 rounded w-full p-0.5">
            <div className="bg-gray-900 rounded w-full p-5">
                <span className="bloc text-gray-100 break-words ">
                    {props.children}
                </span>
            </div>
        </div>
    )
}

export default Code
