import SectionCards2 from '../../Components/SectionCards/SectionCards2'
import SectionHero from '../../Components/SectionHero/SectionHero'

const About = () => {
    return (
        <>
            <SectionHero />
            <SectionCards2 />
        </>
    )
}

export default About
