import Code from './Code'
import Paragraphe from './Paragraphe'
import Title from './Title'
import Image from './Image'

interface IArticle {
    categ: string
    date: string
    repo: string
    title: string
    description: string
    details: [
        {
            type: string
            value: string
        }
    ]
}

const Article = (props: any) => {
    const articleJson: IArticle = require('../../Contents/React_Native/Project_GeoLocation.json')

    return (
        <div className="bg-gray-1000">
            <div className="max-w-screen-2xl bg-gray-1000 mx-auto p-5">
                <h1 className="px-5 pt-10 pb-5 mt-10 text-3xl md:text-6xl font-semibold text-transparent bg-clip-text bg-gradient-to-tr from-red-700 to-blue-400">
                    {articleJson.title}
                </h1>
                <p className="px-5 my-5 text-gray-400">
                    {articleJson.description}
                </p>
                {articleJson.details.map(({ type, value }) => {
                    switch (type) {
                        case 'code': {
                            return <Code>{value}</Code>
                        }
                        case 'title': {
                            return <Title>{value}</Title>
                        }
                        case 'paragraphe': {
                            return <Paragraphe>{value}</Paragraphe>
                        }
                        case 'image': {
                            return <Image source={value}></Image>
                        }
                    }
                    return ''
                })}
            </div>
        </div>
    )
}

export default Article
