import SectionCard3 from './SectionCard3'

const cardsJson = [
    {
        title: 'WSL2',
        text: 'Bien debuter avec WSL2',
        logo: 'logoWsl2.png',
    },
    {
        title: 'Docker',
        text: 'Les base de Docker',
        logo: 'logoDocker.png',
    },
    {
        title: 'React',
        text: 'Les bases de React',
        logo: 'logoReact.png',
    },
    {
        title: 'Github',
        text: 'Les bases de Github',
        logo: 'logoGithub.png',
    },
    {
        title: 'React-Native',
        text: 'Les base de React-Native',
        logo: 'logoReact.png',
    },
]

const SectionCards = () => {
    return (
        <>
            <div className=" bg-gray-900">
                <div className="max-w-screen-2xl mx-auto flex flex-col">
                    <h1 className="text-center mt-5 text-3xl text-gray-100">
                        Les categories
                    </h1>
                    <div className=" flex flex-col md:flex-row items-center justify-center flex-wrap">
                        {cardsJson.map((card) => {
                            return (
                                <SectionCard3
                                    title={card.title}
                                    text={card.text}
                                    logo={card.logo}
                                />
                            )
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}

export default SectionCards
